.form{
  &_after_success{
    position: relative;
    &.success {
      &:after {
        content: 'Спасибо за заявку!';
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3rem;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: white;
        border: 1px solid black;
      }

      & > * {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
}